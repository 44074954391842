.experience {
  height: 100%;
  min-height: 100vh;
  padding: 5%;
  background-color: black;
  color: beige;
}

.experience .content {
  display: flex;
  flex-direction: column;
}

.experience .content .experienceHeading {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  font-size: 200%;
  align-items: center;
}

.experience .content .experienceHeading .dateRange {
  display: flex;
  justify-content: flex-end;
  font-size: medium;
  width: 80%;
}

.experience .content .experienceHeading .name {
  width: 100%;
  text-align: center;
}

.experience .content .experienceHeading .name h1 {
  position: relative;
  right: 15%;
}

.experience .content .experienceHeading .role {
  width: 100%;
  text-align: center;
}

.experience .content .experienceHeading .role h1 {
  position: relative;
  left: 10%;
  font-weight: 100;
}

.experience .content .experienceHeading h1 {
  margin: 0;
}

.experience .content .experienceContent {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  margin-top: 5%;
}

.experience .content .experienceContent img {
  width: 30%;
  height: 30%;
  border-radius: 5%;
}

.experience .content .experienceContent .experienceDescription {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.skills {
  margin: 8px;
}

.skills * {
  color: beige;
  margin: 4px;
}
