.contactMe {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: black;
  color: beige;
}

.contactMe ul {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.contactMe ul li {
  display: flex;
  align-items: center;
}

.contactMe ul li a {
  text-decoration: none;
  color: beige;
  display: flex;
  align-items: center;
}

.contactMe ul li .icon {
  color: beige;
  margin: 5px;
}