.education {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  justify-content: flex-start;
  align-items: center;
}

.education::before {
  content: "";
  background-image: url('../../../public/assets/images/SFU.jpg');
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  filter: brightness(20%);
}

.education .content {
  position: relative;
  left: 10%;
  width: 50%;
  padding: 16px;
}

.educationText {
  color: beige;
}