.downloadButton {
  padding: 16px;
  background-color: black;
  color: beige;
  border-radius: 16px;
  border-color: beige;
  font-size: large;
  font-weight: bold;
}

.downloadButton:hover {
  color: black;
  background-color: beige;
}