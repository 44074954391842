.mainNav {
  width: 100%;
  display: flex;
  background-color: black;
  color: beige;
  height: 10vh;
  position: sticky;
  top: 0;
  z-index: 1;
}

.mainNav .navItems {
  display: flex;
  width: 100%;
  padding-left: 0;
  align-items: center;
  margin: 0;
  height: 100%;
  list-style: none;
}

.mainNav ul li {
  display: flex;
  height: 100%;
  flex-grow: 1;
}

.mainNav ul li a {
  width: 100%;
  color: beige;
  height: 100%;
  transition-duration: 0.4s;
  text-decoration: none;
}

.mainNav ul li a:hover {
  background-color: beige;
  color: black;
}

.mainNav ul li a .linkText {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}