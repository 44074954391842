.introduction {
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: flex-end;
}

.introduction::before {
  content: "";
  background-image: url('../../../public/assets/images/AboutMe.jpg');
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  filter: brightness(20%);
}

.introduction .content {
  position: relative;
  right: 10%;
  width: 50%;
  padding: 16px;
}

.introductionText {
  color: beige;
}